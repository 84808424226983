<script>
import i18n from '@/i18n';
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import { authComputed, campaignMethods, checkPermission, keyMaster, listPaging, masterMethods } from '@/state/helpers';
import Multiselect from 'vue-multiselect';
import LoadingIcon from '@/components/Loading/main.vue';
import Pagination from '@/components/Pagination/main.vue';
import moment from 'moment';
import PopUpExportSchedule from './popup/schedule/main.vue';
import PopUpFAQ from '@/views/pages/documents/popup/faq/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '立ち上げ資料',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        PopUpFAQ,
        Pagination,
        LoadingIcon,
        Multiselect,
        Layout,
        PopUpExportSchedule,
        MultiSelectCommon,
        Footer
    },
    data() {
        return {
            activeTab: 0,
            listDataMasterCampaign: [],
            listDataMasterRuleGeneral: [],
            listDataMasterRuleGeneralCheck: {
                platform: [],
                rules: []
            },
            listDataMasterRuleClose: [],
            listDataMasterRuleCloseCheck: {
                platform: [],
                rules: []
            },
            dataRuleTab2: {},
            dataRuleTab2Check: [],
            dataRuleTab: [],
            listTab2: [],
            historyListDocument: [],
            listDataMasterClients: [],
            listDataMasterAgencies: [],
            isLoading: true,
            isLoadingTable: true,
            checkFirst: false,
            checkSecond: false,
            idActive: '',
            indexActive: '',
            clientCondition: {
                id: '',
                name: ''
            },
            clientCondition1: {
                id: '',
                name: ''
            },
            agencyCondition: {
                id: '',
                name: ''
            },
            agencyCondition1: {
                id: '',
                name: ''
            },
            partnerCondition: null,
            configPartner: {
                trackBy: 'id',
                label: 'name',
                error: false,
                errorPartner: '',
                isRemove: false
            },
            optionsPartner: [],
            listDataMasterCampaignType: [],
            campaign: {
                id: '',
                name: ''
            },
            listNotCondition: [
                {
                    label: 'エスカレーション',
                    data: [
                        {
                            id: '1',
                            source: 'escalation',
                            name: '【エスカレーション】 ●●●キャンペーン_yyyymmdd.xlsx'
                        },
                        {
                            id: '2',
                            source: 'escalation_for_partner',
                            name: '※パートナー用※【エスカレーション】 ●●●キャンペーン_yyyymmdd.xlsx'
                        },
                        {
                            id: '3',
                            source: 'escalation_for_internal',
                            name: '※社内用※【エスカレーション】 ●●●キャンペーン_yyyymmdd.xlsx'
                        }
                    ]
                },
                {
                    label: 'PDCCA アポ資料',
                    data: [
                        {
                            id: '4',
                            source: 'pdca',
                            name: 'PDCAアポ提案資料.pptx'
                        }
                    ]
                },
                {
                    label: '通数FMT',
                    data: [
                        {
                            id: '22',
                            source: 'fmt_twitter_instagram',
                            name: '【日別応募数報告(Twitter・Instagram集計)】(第●回)キャンペーン名称_yyyymmdd.xlsx'
                        },
                        {
                            id: '23',
                            source: 'fmt_web',
                            name: '【日別応募数報告(WEB集計)】(第●回)キャンペーン名称_yyyymmdd.xlsx'
                        },
                        {
                            id: '24',
                            source: 'fmt_postcard',
                            name: '【日別応募数報告(〆計・はがき集計あり)】(第● 回)キャンペーン名称_yyyymmdd.xlsx'
                        },
                        {
                            id: '25',
                            source: 'fmt_web_postcard',
                            name: '【日別応募数報告(ハガキ・WEB×ハガキ集計)】(第●回)キャンペーン名称_yyyymmdd.xlsx'
                        }
                    ]
                },
                {
                    label: 'お問い合わせFMT',
                    data: [
                        {
                            id: '26',
                            source: 'fmt_entire_company',
                            name: '※代理店用※【問い合せ報告】●●●キャンペーン__yyyymmdd　祝日一覧無し.xlsx'
                        },
                        {
                            id: '27',
                            source: 'fmt_agency',
                            name: '※社内全体用※【問い合せ報告】 ●●●キャンペーン__yyyymmdd　祝日一覧無し.xlsx'
                        }
                    ]
                }
            ],
            listDataMasterTemplate: [],
            listCondition: [
                {
                    label: this.$t('document.manual'),
                    data: [
                        {
                            id: '5',
                            source: 'horizontal_manual',
                            name: '横・【事務局マニュアル】 CP名_yyyymmdd.pptx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            agency: {
                                agency_id: '',
                                agency_name: ''
                            },
                            partner: {
                                id: '',
                                name: ''
                            }
                        },
                        {
                            id: '6',
                            source: 'vertical_manual',
                            name: '縦・【事務局マニュアル】 CP名_yyyymmdd.pptx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            agency: {
                                agency_id: '',
                                agency_name: ''
                            },
                            partner: {
                                id: '',
                                name: ''
                            }
                        }
                    ]
                },
                {
                    label: this.$t('document.greetingCard'),
                    data: [
                        {
                            id: '7',
                            name: '※POP※【挨拶状】_●●●CP_yyyymmdd.pptx',
                            source: 'pop_greeting_card',
                            client: {
                                client_id: '',
                                client_name: ''
                            }
                        },
                        {
                            id: '8',
                            source: 'flower_greeting_card',
                            name: '※お花※【挨拶状】_●●●CP_yyyymmdd.pptx',
                            client: {
                                client_id: '',
                                client_name: ''
                            }
                        },
                        {
                            id: '9',
                            source: 'luxury_greeting_card',
                            name: '※高級感※【挨拶状】_●●●CP_yyyymmdd.pptx',
                            client: {
                                client_id: '',
                                client_name: ''
                            }
                        },
                        {
                            id: '10',
                            source: 'normal_without_replacement_greeting_card',
                            name: '※差換え無し※【挨拶状】_●●●CP_yyyymmdd.pptx',
                            client: {
                                client_id: '',
                                client_name: ''
                            }
                        },
                        {
                            id: '11',
                            source: 'normal_with_replacement_greeting_card',
                            name: '※差換え有※(賞名orコース)【挨拶状】_●●●CP_yyyymmdd.pptx',
                            client: {
                                client_id: '',
                                client_name: ''
                            }
                        }
                    ]
                },
                {
                    label: this.$t('document.termsAndConditions'),
                    data: [
                        {
                            id: '47',
                            source: 'line',
                            name: '規約雛形【LINE(CR)ﾚｼ】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        },
                        {
                            id: '41',
                            source: 'sns_image',
                            name: '規約雛形【SNS#画像】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        },
                        {
                            id: '42',
                            source: 'sns_work_image',
                            name: '規約雛形【SNS#作品画像】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        },
                        {
                            id: '43',
                            source: 'twi',
                            name: '規約雛形【Twi#】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        },
                        {
                            id: '44',
                            source: 'twirt',
                            name: '規約雛形【TwiRT】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        },
                        {
                            id: '45',
                            source: 'web',
                            name: '規約雛形【WEBﾚｼ】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        },
                        {
                            id: '46',
                            source: 'postcard',
                            name: '規約雛形【ハガキ】.docx',
                            client: {
                                client_id: '',
                                client_name: ''
                            },
                            rulesCheckTab1: [],
                            rulesCheckTab2: []
                        }
                    ]
                },
                {
                    label: this.$t('document.schedule'),
                    data: [
                        {
                            id: '13',
                            source: 'schedule',
                            name: '※社内用※【CPスケジュールタスク管理シート】'
                        }
                    ]
                },
                {
                    label: this.$t('document.winningDistributionText'),
                    data: []
                },

                {
                    label: this.$t('document.partnerRequestSheet'),
                    isCheckbox: true,
                    data: [{ id: '19', name: '事務局依頼シート', path: '/require' }]
                },
                {
                    label: this.$t('document.faq'),
                    data: [
                        {
                            id: '20',
                            source: 'faq',
                            name: '雛形CL※【FAQ】CP名_yyyymmdd.xlsx',
                            preview: true
                        }
                        // {
                        //   id: "21",
                        //   name: "雛形OP※【FAQ】CP名_yyyymmdd.xlsx",
                        //   preview: true,
                        // },
                    ]
                }
            ],
            conditions: {},
            total: 0,
            limit: 10,
            page: 1,
            campaignType: '',
            perPageList: listPaging,
            templateDetail: {},
            documentPreviewData: {},
            configExportSchedule: {
                id: '',
                code: '',
                isShowModal: false,
                dataCPType: [],
                dataDocument: [],
                listIDWorks: []
            },
            configFaq: {
                isShowModal: false,
                isLoadingPreviewFAQ: false,
                listData: {},
                listTitle: {}
            },
            dataDetailForDocument: null,
            detailBeginForDocument: null,
            objExportSchedule: null,
            modalConditionOtherObj: {},
            modalConditionOtherId: '',
            listChildConditionAfterCheckedTab1: [],
            listChildConditionAfterCheckedTab2: [],
            listIDConditionTab2Checked: [],
            listConditionRuleDetail: [],
            isLoadingConditionOther: false,
            isLoadingTemplate: false
        };
    },
    watch: {
        campaign: function (newName) {
            if (newName?.id) {
                this.getListMasterCampaignDetail(newName);
                this.listDataMasterClients = this.removeDuplicates(newName.clients);
                this.listDataMasterAgencies = this.removeDuplicates(newName.agencies);
                this.objExportSchedule = null;
            }
        },
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getHistory();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getHistory();
            }
        },
        reload: function () {
            this.getHistory();
        },
        objExportSchedule: function (newName) {
            this.listCondition.forEach((item) => {
                item.data.forEach((i) => {
                    if (item.label === this.$t('document.schedule')) {
                        if (i.id === newName?.parentID) {
                            i.condition = newName;
                        }
                    }
                });
            });
        }
    },
    mounted() {
        this.getListMasterCampaign();
        this.getListMasterTemplate();
        this.getAllRule();
        this.getListMasterCampaignType();
    },
    computed: {
        ...authComputed
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,
        clearDataOther() {
            this.listDataMasterRuleGeneralCheck.rules = [];
            this.listIDConditionTab2Checked = [];
            this.dataRuleTab = [];
            this.listChildConditionAfterCheckedTab1 = [];
            this.listChildConditionAfterCheckedTab2 = [];
            this.listTab2.forEach((item) => {
                if (this.dataRuleTab.includes(item.id)) {
                    if (this.dataRuleTab2[item.id] && this.dataRuleTab2[item.id].length > 0) {
                        this.dataRuleTab2Check[item.id].forEach((item1) => {
                            item1.rules = [];
                        });
                    }
                }
            });

            if (!this.modalConditionOtherObj || !this.modalConditionOtherObj[this.modalConditionOtherId]) return;
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab1 = [];
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab2 = [];
            this.modalConditionOtherObj[this.modalConditionOtherId].categories = [];
            this.handleGetDetailConditionsTab1(this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab1);
            this.handleGetDetailConditionsTab2(this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab2, null);
        },
        getListMasterCampaignType() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataMasterCampaignType = data;
            });
        },
        getAllRule() {
            this.listMasterRule1().then((data) => {
                if (data.code == 200) {
                    this.listConditionRuleDetail = data.data;
                    data.data.forEach((item) => {
                        if (item.type == 1) {
                            item.rules = [];
                            this.listDataMasterRuleGeneral.push(item);
                            this.listDataMasterRuleGeneralCheck.platform.push(item);
                        } else if (item.type == 2) {
                            item.rules = [];
                            this.listDataMasterRuleClose.push(item);
                            this.listDataMasterRuleCloseCheck.platform.push(item);
                        } else {
                            item.rules = [];
                            if (item.platforms.length > 0) {
                                item.platforms.forEach((pl) => {
                                    if (!Array.isArray(this.dataRuleTab2[pl.id])) {
                                        this.dataRuleTab2[pl.id] = [];
                                    }
                                    this.dataRuleTab2[pl.id].push(item);
                                });
                            }
                            this.dataRuleTab2Check = JSON.parse(JSON.stringify(this.dataRuleTab2));
                        }
                    });
                }
            });
        },
        changeTab() {
            this.modalConditionOtherObj[this.modalConditionOtherId].campaignType = this.campaignType;
            this.dataRuleTab2Check = JSON.parse(JSON.stringify(this.dataRuleTab2));
            this.listDataMasterCampaignType.forEach((item) => {
                if (item.value == this.modalConditionOtherObj[this.modalConditionOtherId].campaignType) {
                    this.listTab2 = item.data;
                }
            });
            this.clearDataOther();
        },

        getListMasterTemplate() {
            this.templateList().then((data) => {
                if (data.code == 200) {
                    this.listDataMasterTemplate = data.data;
                    this.listCondition.forEach((item) => {
                        if (item.label == '当選配信文言') {
                            data.data.forEach((jtem) => {
                                jtem.source = jtem.id;
                                item.data.push(jtem);
                                jtem.id = jtem.id + 'c';
                            });
                        }
                    });
                }
            });
        },
        removeDuplicates(objects) {
            const map = {}; // Sử dụng một đối tượng bản đồ để theo dõi các ID đã xuất hiện
            const result = [];

            // Lặp qua mảng đối tượng
            for (const obj of objects) {
                // Nếu ID không tồn tại trong bản đồ, thêm đối tượng vào mảng kết quả và đánh dấu ID đã xuất hiện
                if (!map[obj.id]) {
                    result.push(obj);
                    map[obj.id] = true;
                }
            }

            return result;
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getListMasterCampaign() {
            this.getAllDocuments().then((data) => {
                if (data.code == 200) {
                    this.listDataMasterCampaign = data.data;
                    if (this.$route.query.id) {
                        let index = this.listDataMasterCampaign.findIndex((x) => {
                            return x.id == this.$route.query.id;
                        });
                        if (index >= 0) {
                            this.campaign = this.listDataMasterCampaign[index];
                        }
                    } else if (this.currentUser.default_campaign) {
                        let index = this.listDataMasterCampaign.findIndex((x) => {
                            return x.id == this.currentUser.default_campaign.id;
                        });
                        if (index >= 0) {
                            this.campaign = this.listDataMasterCampaign[index];
                        }
                    }
                }
            });
        },
        getListMasterCampaignDetail() {
            this.getDetailDocuments(this.campaign).then((data) => {
                if (data.code == 200) {
                    this.detailBeginForDocument = data.data;
                    this.dataRuleTab = [];
                    this.optionsPartner =
                        data.data?.partner_shipping_type.map((item) => {
                            return {
                                id: item.id,
                                name: item.name
                            };
                        }) ?? [];
                    this.campaignType = data.data.campaign_type ? data.data.campaign_type.value : '';

                    if (data.data.campaign_type) {
                        this.listDataMasterCampaignType.forEach((item) => {
                            if (item.id == data.data.campaign_type.id) {
                                this.listTab2 = item.data;
                            }
                        });
                    }

                    data.data.categories.forEach((item) => {
                        this.dataRuleTab.push(item.category_id);
                    });
                }
            });
        },
        setData(label, data, key, jey) {
            this.indexActive = key;
            this.idActive = jey;
            if (data.client) {
                this.clientCondition = JSON.parse(JSON.stringify(data.client));
                this.clientCondition1 = JSON.parse(JSON.stringify(data.client));
            }

            if (data.agency) {
                this.agencyCondition = data?.agency ? JSON.parse(JSON.stringify(data?.agency)) : null;
                this.agencyCondition1 = data?.agency ? JSON.parse(JSON.stringify(data?.agency)) : null;
            }
            if (data.partner) {
                this.partnerCondition = data?.partner?.id ? JSON.parse(JSON.stringify(data?.partner)) : null;
            }
            switch (label) {
                case this.$t('document.schedule'):
                    if (this.objExportSchedule && this.objExportSchedule.campaign_start_date) {
                        this.detailBeginForDocument = JSON.parse(JSON.stringify(this.detailBeginForDocument));
                        this.dataDetailForDocument.campaign_start_date = this.objExportSchedule.campaign_start_date;
                        this.dataDetailForDocument.campaign_end_date = this.objExportSchedule.campaign_end_date;
                        this.dataDetailForDocument.secretariat_end_date = this.objExportSchedule.secretariat_end_date;
                        this.configExportSchedule.dataDocument.categories = this.objExportSchedule.categories;
                    } else {
                        this.dataDetailForDocument = this.detailBeginForDocument;
                    }
                    this.configExportSchedule.id = label;
                    this.configExportSchedule.code = data.id;
                    this.configExportSchedule.isShowModal = true;
                    this.configExportSchedule.dataCPType = this.listDataMasterCampaignType;
                    this.configExportSchedule.dataDocument = this.dataDetailForDocument;
                    this.configExportSchedule.dateShipping = this.objExportSchedule?.date_shipping;
                    this.configExportSchedule.listIDWorks = this.objExportSchedule?.titles ?? [];

                    this.$bvModal.show(label);
                    break;
                case this.$t('document.greetingCard'):
                    this.agencyCondition1 = null;
                    this.$bvModal.show('modal-condition');
                    break;
                case this.$t('document.manual'):
                    this.$bvModal.show('modal-condition');
                    break;
                default:
                    break;
            }
        },

        handleGetRuleSavePayLoad(dataSource, selectSource) {
            this.getRuleSavePayLoad(dataSource).then((data) => {
                let dataResponse = data.data;
                if (dataResponse && dataResponse.id) {
                    this.campaignType = dataResponse.campaign_type ? dataResponse.campaign_type.value : '';
                    this.dataRuleTab = dataResponse.categories;
                    this.modalConditionOtherObj[selectSource.id].categories = dataResponse.categories;
                    this.modalConditionOtherObj[selectSource.id].campaignType = dataResponse.campaign_type ? dataResponse.campaign_type.value : '';
                    this.clientCondition = dataResponse.client_id;
                    this.listConditionRuleDetail.forEach((masterRule) => {
                        if (dataResponse.rules.includes(masterRule.id)) {
                            if (masterRule.type === 1 || masterRule.type === 2) {
                                if (!this.listDataMasterRuleGeneralCheck.rules.includes(masterRule.id)) {
                                    this.listDataMasterRuleGeneralCheck.rules.push(masterRule.id);
                                }
                                if (!selectSource.rulesCheckTab1.includes(masterRule.id)) {
                                    selectSource.rulesCheckTab1.push(masterRule.id);
                                }
                            } else {
                                if (!selectSource.rulesCheckTab2.includes(masterRule.id)) {
                                    selectSource.rulesCheckTab2.push(masterRule.id);
                                }

                                if (!this.listIDConditionTab2Checked.includes(masterRule.id)) {
                                    this.listIDConditionTab2Checked.push(masterRule.id);
                                }
                            }
                        }
                    });

                    this.listDataMasterCampaignType.forEach((item) => {
                        if (item.value == this.modalConditionOtherObj[selectSource.id].campaignType) {
                            this.listTab2 = item.data;
                        }
                    });

                    this.listTab2.forEach((item) => {
                        if (this.modalConditionOtherObj[selectSource.id].categories.includes(item.id)) {
                            if (this.dataRuleTab2Check[item.id]) {
                                this.dataRuleTab2Check[item.id].forEach((item1) => {
                                    if (selectSource.rulesCheckTab2.includes(item1.id)) item1.rules = [item1.id];
                                });
                            } else {
                                if (this.dataRuleTab2[item.id]) {
                                    this.dataRuleTab2Check[item.id].forEach((item1) => {
                                        if (selectSource.rulesCheckTab2.includes(item1.id)) item1.rules = [item1.id];
                                    });
                                }
                            }
                        }
                    });
                    this.handleGetDetailConditionsTab1(selectSource.rulesCheckTab1);
                    this.handleGetDetailConditionsTab2(selectSource.rulesCheckTab2, null);
                } else {
                    this.getListMasterCampaignDetail();
                }
                this.isLoadingConditionOther = false;
            });
        },

        handleSaveRulePayLoad(dataPayload) {
            this.postRuleSavePayLoad(dataPayload).then((data) => {
                if (data.code == 200) {
                    this.$bvToast.toast(`success`, {
                        title: `更新されました。`,
                        variant: 'success',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                }
            });
        },

        setDataOther(data, key, jey) {
            this.isLoadingConditionOther = true;
            this.indexActive = key;
            this.modalConditionOtherId = data.id;
            this.idActive = jey;
            this.clearDataOther();
            this.modalConditionOtherObj[this.modalConditionOtherId] = data;
            this.clientCondition = JSON.parse(JSON.stringify(data.client));
            this.clientCondition1 = JSON.parse(JSON.stringify(data.client));
            let dataSource = {
                campaign_id: this.detailBeginForDocument.id,
                source: data.source
            };
            this.handleGetRuleSavePayLoad(dataSource, data);

            this.listDataMasterRuleCloseCheck.platform = JSON.parse(JSON.stringify(this.listDataMasterRuleClose));

            this.listDataMasterRuleGeneralCheck.platform = JSON.parse(JSON.stringify(this.listDataMasterRuleGeneral));

            this.dataRuleTab2Check = JSON.parse(JSON.stringify(this.dataRuleTab2));

            this.$bvModal.show('modal-condition-other');
        },
        setConditionRule() {
            this.checkSecond = true;
            if (!this.clientCondition.id) {
                return;
            }
            this.listCondition[this.indexActive].data[this.idActive].client = this.clientCondition;

            this.modalConditionOtherObj[this.modalConditionOtherId].categories = this.dataRuleTab;
            let itemConditionSave = this.modalConditionOtherObj[this.modalConditionOtherId];

            let payload = {
                campaign_id: this.detailBeginForDocument.id,
                source: itemConditionSave.source,
                data: {
                    client_id: itemConditionSave.client.id ?? null,
                    rules: itemConditionSave.rulesCheckTab1.concat(itemConditionSave.rulesCheckTab2),
                    campaign_type: this.listDataMasterCampaignType.find((x) => {
                        if (itemConditionSave.campaignType) {
                            return x.value == itemConditionSave.campaignType;
                        } else {
                            return x.value == this.campaignType;
                        }
                    }).id,
                    categories: itemConditionSave.categories
                }
            };
            this.handleSaveRulePayLoad(payload);
            this.$bvModal.hide('modal-condition-other');
            this.checkSecond = false;
        },
        setCondition() {
            this.checkSecond = true;
            if (!this.clientCondition.id) return;
            this.listCondition[this.indexActive].data[this.idActive].client = this.clientCondition;
            this.$bvModal.hide('modal-condition');
            this.checkSecond = false;
        },
        setConditionManual() {
            this.checkSecond = true;
            if (!this.clientCondition.id || (this.agencyCondition && !this.agencyCondition.id) || !this.partnerCondition) {
                if (!this.partnerCondition) {
                    this.configPartner.errorPartner = i18n.t('validateField.requiredSelect', {
                        field: '外部パートナー'
                    });
                }
                return;
            }
            this.listCondition[this.indexActive].data[this.idActive].client = this.clientCondition;
            this.listCondition[this.indexActive].data[this.idActive].agency = this.agencyCondition;
            this.listCondition[this.indexActive].data[this.idActive].partner = this.partnerCondition;
            this.$bvModal.hide('modal-condition');
            this.checkSecond = false;
        },
        register() {
            this.checkFirst = true;
            this.isLoading = false;
            if (this.campaign.id === '') {
                this.isLoading = true;
            } else {
                let documents = [];
                let check = false;
                this.listCondition.forEach((item) => {
                    item.data.forEach((i) => {
                        if (this.conditions[i.id]) {
                            if (item.label == '規約') {
                                let rules = [];

                                if (i.rulesCheckTab1.length > 0) {
                                    rules.push(...i.rulesCheckTab1);
                                }
                                if (i.rulesCheckTab2.length > 0) {
                                    rules.push(...i.rulesCheckTab2);
                                }
                                if (rules.length == 0) {
                                    check = true;
                                }

                                documents.push({
                                    source: i.source,
                                    data: {
                                        client_id: i.client ? i.client.id : '',
                                        rules: rules
                                    }
                                });
                            } else if (item.label === this.$t('document.schedule')) {
                                delete i.condition.parentID;
                                delete i.condition.categories;
                                documents.push({
                                    source: i.source,
                                    data: i.condition
                                });
                            } else if (item.label === 'FAQ') {
                                documents.push({
                                    source: i.source,
                                    data: {}
                                });
                            } else {
                                if ((i.client && !i.client.id) || (i.agency && !i.agency.id) || (i.partner && !i.partner.id)) {
                                    check = true;
                                }
                                documents.push({
                                    source: i.source,
                                    data: {
                                        client_id: i.client ? i.client.id : '',
                                        agency_id: i.agency ? i.agency.id : '',
                                        partner_shipping_id: i.partner ? i.partner.id : ''
                                    }
                                });
                            }
                        }
                    });
                });
                this.listNotCondition.forEach((item) => {
                    item.data.forEach((i) => {
                        if (this.conditions[i.id]) {
                            documents.push({
                                source: i.source,
                                data: {}
                            });
                        }
                    });
                });
                let objectData = {
                    campaign_id: this.campaign.id,
                    documents: documents
                };
                if (check == true) {
                    this.isLoading = true;
                    this.$bvToast.toast(`error`, {
                        title: '出力条件を設定してください。',
                        variant: 'danger',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                    return;
                } else {
                    this.postDocuments(objectData).then((data) => {
                        if (data.code == 200) {
                            this.$bvToast.toast(`Success`, {
                                title: `登録されました。`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                            this.objExportSchedule = null;
                        }
                        if (data.code == 500) {
                            this.$bvToast.toast(`error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                        if (data.code == 422) {
                            this.$bvToast.toast(`error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                        this.isLoading = true;
                    });
                }
            }
        },
        getHistory() {
            this.historyListDocument = [];
            this.isLoadingTable = false;
            let query = this.getQueryString();
            this.historyDocuments(query).then((data) => {
                if (data.code == 200) {
                    this.historyListDocument = data.data;
                    this.total = data._metadata.total;
                    this.limit = data._metadata.limit * 1;
                }

                this.isLoadingTable = true;
            });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];
            return query.join('&');
        },
        handleTabClick(tabNumber) {
            this.activeTab = tabNumber;
            if (tabNumber == 1) this.getHistory();
        },
        goToEdit(path) {
            this.$router.push({ path: path });
        },
        getCurrentDateTime(date) {
            var now = new Date(date);

            var year = now.getFullYear();
            var month = ('0' + (now.getMonth() + 1)).slice(-2);
            var day = ('0' + now.getDate()).slice(-2);

            var hours = ('0' + now.getHours()).slice(-2);
            var minutes = ('0' + now.getMinutes()).slice(-2);
            var seconds = ('0' + now.getSeconds()).slice(-2);
            var formattedDateTime = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;

            return formattedDateTime;
        },
        showPreview(id) {
            this.templateDetail = {};
            this.getTemplateDetail(id);
            this.$bvModal.show('modal-preview');
        },
        setPreview() {
            if (!this.campaign.id) return;
            this.configFaq.listData = {};
            this.configFaq.listTitle = {};
            this.configFaq.listFAQ = {};
            this.configFaq.isShowModal = true;
            this.configFaq.isLoadingPreviewFAQ = true;
            this.getDocumentFAQ(this.campaign).then((data) => {
                if (data.code == 200) {
                    data.data.forEach((item) => {
                        if (this.configFaq.listData && !this.configFaq.listData[item.sheet_name]) {
                            this.configFaq.listData[item.sheet_name] = [];
                        }
                        item.answers.forEach((aTem) => {
                            if (!this.configFaq.listTitle[item.sheet_name]) this.configFaq.listTitle[item.sheet_name] = {};
                            if (this.configFaq.listTitle[item.sheet_name] && !this.configFaq.listTitle[item.sheet_name][aTem.category_id])
                                this.renderNameTitle(item.sheet_name, aTem.category_id);
                        });
                        if (Array.isArray(this.configFaq.listData[item.sheet_name])) {
                            this.configFaq.listData[item.sheet_name].push(item);
                        }
                    });
                    setTimeout(() => {
                        data.data.forEach((item) => {
                            if (Array.isArray(this.configFaq.listData[item.sheet_name])) {
                                this.configFaq.listData[item.sheet_name] = this.configFaq.listData[item.sheet_name].reduce((acc, curr) => {
                                    const { question_id } = curr;
                                    if (!acc[question_id]) {
                                        acc[question_id] = {
                                            campaign_id: curr.campaign_id,
                                            id: curr.id,
                                            question: this.replaceQ(curr.question),
                                            question_id: curr.question_id,
                                            sheet_id: curr.sheet_id,
                                            answers: []
                                        };
                                    }
                                    acc[question_id].answers = acc[question_id].answers.concat(this.replaceA(curr.answers));
                                    return acc;
                                }, {});
                            }
                        });
                        this.configFaq.isLoadingPreviewFAQ = false;
                        this.configFaq = JSON.parse(JSON.stringify(this.configFaq));
                    }, 1000);
                }
            });
            this.campaign.campaign_id = this.campaign.id;
            this.campaign.source = 'faq';
            this.postDocumentFAQPreview(this.campaign).then((data) => {
                if (data.code == 200) {
                    let context_data = data.data.context_data;
                    this.postDocumentFAQ(this.campaign).then((dat) => {
                        if (dat.code == 200) {
                            for (const [key, item] of Object.entries(dat.data)) {
                                item.forEach((i, k) => {
                                    dat.data[key][k] = dat.data[key][k].replace(/《キャンペーン名》/g, context_data.name);
                                    if (context_data.campaign_start_date)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《キャンペーン開始》/g,
                                            moment(context_data.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                                        );
                                    if (context_data.tel) dat.data[key][k] = dat.data[key][k].replace(/《キャンペーン事務局TEL》/g, context_data.tel);
                                    if (context_data.email)
                                        dat.data[key][k] = dat.data[key][k].replace(/《キャンペーン事務局Mailアドレス》/g, context_data.email);
                                    if (context_data.secretariat_start_date)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《事務局開始》/g,
                                            moment(context_data.secretariat_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                                        );
                                    if (context_data.secretariat_end_date)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《事務局終了》/g,
                                            moment(context_data.secretariat_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                                        );
                                    if (context_data.year_end_schedule_from || context_data.year_end_schedule_to)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《年末年始休み》/g,
                                            `(${moment(context_data.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                                                context_data.year_end_schedule_to
                                            )
                                                .locale('ja')
                                                .format('YYYY年MM月DD日（ddd）')})`
                                        );
                                });
                            }
                            this.configFaq.listFAQ = JSON.parse(JSON.stringify(dat.data));
                        }
                    });
                }
            });
        },

        replaceQ(data) {
            let context_data = this.detailBeginForDocument;
            if (context_data.name) data = data.replace(/《キャンペーン名》/g, context_data.name);
            if (context_data.group_total) data = data.replace(/《グループの合計》/g, context_data.group_total);
            if (context_data.include_group_target_product)
                data = data.replace(/《グループの対象商品含む》/g, context_data.include_group_target_product);
            if (context_data.group_target_product) data = data.replace(/《グループの対象商品》/g, context_data.group_target_product);
            if (context_data.instagram_name) data = data.replace(/《公式Instagramアカウント名》/g, context_data.instagram_name);
            if (context_data.year_end_schedule_formatted) data = data.replace(/《年末年始休み》/g, context_data.year_end_schedule_formatted);
            if (context_data.url) data = data.replace(/《キャンペーンサイトURL》/g, context_data.url);
            if (context_data.hashtag_formatted) data = data.replace(/《CP指定ハッシュタグ》/g, context_data.hashtag_formatted);
            if (context_data.categories_formatted) data = data.replace(/《応募種別》/g, context_data.categories_formatted);
            if (context_data.clients_formatted) data = data.replace(/《クライアント名》/g, context_data.clients_formatted);
            if (context_data.campaign_start_date)
                data = data.replace(/《キャンペーン開始》/g, moment(context_data.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）'));
            if (context_data.campaign_end_date)
                data = data.replace(/《キャンペーン終了》/g, moment(context_data.campaign_end_date).locale('ja').format('YYYY年MM月DD日（ddd）'));
            if (context_data.tel) data = data.replace(/《キャンペーン事務局TEL》/g, context_data.tel);
            if (context_data.email) data = data.replace(/《キャンペーン事務局Mailアドレス》/g, context_data.email);
            if (context_data.secretariat_start_date)
                data = data.replace(/《事務局開始》/g, moment(context_data.secretariat_start_date).locale('ja').format('YYYY年MM月DD日（ddd）'));
            if (context_data.secretariat_end_date)
                data = data.replace(/《事務局終了》/g, moment(context_data.secretariat_end_date).locale('ja').format('YYYY年MM月DD日（ddd）'));
            if (context_data.year_end_schedule_from || context_data.year_end_schedule_to)
                data = data.replace(
                    /《年末年始休み》/g,
                    `(${moment(context_data.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                        context_data.year_end_schedule_to
                    )
                        .locale('ja')
                        .format('YYYY年MM月DD日（ddd）')})`
                );
            return data;
        },
        replaceA(array) {
            let context_data = this.detailBeginForDocument;
            array.forEach((dat, k) => {
                if (context_data.name) array[k].answer = array[k].answer.replace(/《キャンペーン名》/g, context_data.name);
                if (context_data.group_total) array[k].answer = array[k].answer.replace(/《グループの合計》/g, context_data.group_total);
                if (context_data.include_group_target_product)
                    array[k].answer = array[k].answer.replace(/《グループの対象商品含む》/g, context_data.include_group_target_product);
                if (context_data.group_target_product)
                    array[k].answer = array[k].answer.replace(/《グループの対象商品》/g, context_data.group_target_product);
                if (context_data.instagram_name)
                    array[k].answer = array[k].answer.replace(/《公式Instagramアカウント名》/g, context_data.instagram_name);
                if (context_data.year_end_schedule_formatted)
                    array[k].answer = array[k].answer.replace(/《年末年始休み》/g, context_data.year_end_schedule_formatted);
                if (context_data.url) array[k].answer = array[k].answer.replace(/《キャンペーンサイトURL》/g, context_data.url);
                if (context_data.hashtag_formatted)
                    array[k].answer = array[k].answer.replace(/《CP指定ハッシュタグ》/g, context_data.hashtag_formatted);
                if (context_data.categories_formatted) array[k].answer = array[k].answer.replace(/《応募種別》/g, context_data.categories_formatted);
                if (context_data.clients_formatted) array[k].answer = array[k].answer.replace(/《クライアント名》/g, context_data.clients_formatted);
                if (context_data.campaign_start_date)
                    array[k].answer = array[k].answer.replace(
                        /《キャンペーン開始》/g,
                        moment(context_data.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (context_data.campaign_end_date)
                    array[k].answer = array[k].answer.replace(
                        /《キャンペーン終了》/g,
                        moment(context_data.campaign_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (context_data.tel) array[k].answer = array[k].answer.replace(/《キャンペーン事務局TEL》/g, context_data.tel);
                if (context_data.email) array[k].answer = array[k].answer.replace(/《キャンペーン事務局Mailアドレス》/g, context_data.email);
                if (context_data.secretariat_start_date)
                    array[k].answer = array[k].answer.replace(
                        /《事務局開始》/g,
                        moment(context_data.secretariat_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (context_data.secretariat_end_date)
                    array[k].answer = array[k].answer.replace(
                        /《事務局終了》/g,
                        moment(context_data.secretariat_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (context_data.year_end_schedule_from || context_data.year_end_schedule_to)
                    array[k].answer = array[k].answer.replace(
                        /《年末年始休み》/g,
                        `(${moment(context_data.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                            context_data.year_end_schedule_to
                        )
                            .locale('ja')
                            .format('YYYY年MM月DD日（ddd）')})`
                    );
            });
            return array;
        },

        renderNameTitle(sheetName, category_id) {
            this.listDataMasterCampaignType.forEach((item) => {
                item.data.forEach((aTem) => {
                    if (category_id == aTem.id) {
                        this.configFaq.listTitle[sheetName][category_id] = aTem.value;
                    }
                });
            });
        },
        getTemplateDetail(id) {
            let template = {
                id: id ? id.replace('c', '') : ''
            };
            this.isLoading = false;
            this.isLoadingTemplate = true;
            this.templateGetDetail(template).then((data) => {
                if (data.code == 200 && data.data) {
                    this.templateDetail = data.data;
                    if (this.campaign.id) {
                        this.getListMasterTemplate2();
                    }
                    this.isLoadingTemplate = false;
                }
            });
        },
        getListMasterTemplate2() {
            let campaign = {
                campaign_id: this.campaign.id
            };
            this.documentPreview(campaign).then((data) => {
                if (data.code == 200) {
                    this.documentPreviewData = data.data.context_data;
                    let data1 = JSON.parse(JSON.stringify(this.templateDetail.content));
                    if (this.documentPreviewData.name) data1 = data1.replace(/《キャンペーン名》/g, this.documentPreviewData.name);
                    if (this.documentPreviewData.campaign_start_date)
                        data1 = data1.replace(
                            /《キャンペーン開始》/g,
                            moment(this.documentPreviewData.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                        );
                    if (this.documentPreviewData.tel) data1 = data1.replace(/《キャンペーン事務局TEL》/g, this.documentPreviewData.tel);
                    if (this.documentPreviewData.email) data1 = data1.replace(/《キャンペーン事務局Mailアドレス》/g, this.documentPreviewData.email);
                    if (this.documentPreviewData.secretariat_start_date)
                        data1 = data1.replace(
                            /《事務局開始》/g,
                            moment(this.documentPreviewData.secretariat_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                        );
                    if (this.documentPreviewData.secretariat_end_date)
                        data1 = data1.replace(
                            /《事務局終了》/g,
                            moment(this.documentPreviewData.secretariat_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                        );
                    if (this.documentPreviewData.year_end_schedule_from || this.documentPreviewData.year_end_schedule_to)
                        data1 = data1.replace(
                            /《年末年始休み》/g,
                            `(${moment(this.documentPreviewData.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                                this.documentPreviewData.year_end_schedule_to
                            )
                                .locale('ja')
                                .format('YYYY年MM月DD日（ddd）')})`
                        );
                    this.templateDetail.content = JSON.parse(JSON.stringify(data1));
                }
                this.isLoading = true;
            });
        },

        handleGetDetailConditionsTab1(listIdCheck) {
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab1 = listIdCheck;
            this.listChildConditionAfterCheckedTab1 = [];
            this.listConditionRuleDetail.forEach((jtem) => {
                if (listIdCheck.includes(jtem.id)) {
                    this.listChildConditionAfterCheckedTab1.push({
                        textParent: jtem.rule,
                        id: jtem.id,
                        details: jtem.details,
                        backgroundColor: '',
                        color: ''
                    });
                }
            });
        },

        handleSelectTextTab1(idText) {
            if (this.listChildConditionAfterCheckedTab1.length <= 0) return;
            this.listChildConditionAfterCheckedTab1.forEach((item) => {
                if (item.id !== idText) {
                    item.backgroundColor = '';
                    item.color = '';
                } else {
                    item.backgroundColor = '#b7b7b74d';
                    item.color = '#3e3e3e';
                }
            });
        },

        handleGetDetailConditionsTab2(listIdCheck, typeHandle) {
            if (typeHandle === 'model_checked') {
                if (!this.listIDConditionTab2Checked.includes(listIdCheck.id)) {
                    this.listIDConditionTab2Checked.push(listIdCheck.id);
                } else {
                    this.listIDConditionTab2Checked = this.listIDConditionTab2Checked.filter((item) => item !== listIdCheck.id);
                }
            }
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab2 =
                typeHandle === 'model_checked' ? this.listIDConditionTab2Checked : listIdCheck;

            this.listChildConditionAfterCheckedTab2 = [];
            this.listConditionRuleDetail.forEach((jtem) => {
                if (this.listIDConditionTab2Checked.includes(jtem.id)) {
                    this.listChildConditionAfterCheckedTab2.push({
                        textParent: jtem.rule,
                        id: jtem.id,
                        details: jtem.details,
                        backgroundColor: '',
                        color: ''
                    });
                }
            });
        },

        handleSelectTextTab2(idText) {
            if (this.listChildConditionAfterCheckedTab2.length <= 0) return;
            this.listChildConditionAfterCheckedTab2.forEach((item) => {
                if (item.id !== idText) {
                    item.backgroundColor = '';
                    item.color = '';
                } else {
                    item.backgroundColor = '#b7b7b74d';
                    item.color = '#3e3e3e';
                }
            });
        },

        handleChangeTab() {
            this.listChildConditionAfterCheckedTab1.forEach((item) => {
                item.backgroundColor = '';
                item.color = '';
            });
            this.listChildConditionAfterCheckedTab2.forEach((item) => {
                item.backgroundColor = '';
                item.color = '';
            });
        },

        handleChangeCategoryTab2(listCategory) {
            this.modalConditionOtherObj[this.modalConditionOtherId].categories = listCategory;
            this.listTab2.forEach((item) => {
                if (!listCategory.includes(item.id)) {
                    this.dataRuleTab2Check[item.id]?.forEach((item1) => {
                        item1.rules = [];
                        this.listIDConditionTab2Checked = this.listIDConditionTab2Checked.filter((item2) => item2 !== item1.id);
                    });
                }
            });
            this.handleGetDetailConditionsTab2(this.listIDConditionTab2Checked, null);
        },

        handleBgStatus(status) {
            switch (status) {
                case 'success':
                    return '#1cbb8c';
                case 'processing':
                    return '#fea217';
                case 'failed':
                    return '#ea4335';
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div class="">
            <div class="col-lg-12">
                <div class="card form--sroll1 mb-0">
                    <div class="card-body">
                        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" v-model="activeTab">
                            <b-tab @click="handleTabClick(0)" class="border-0">
                                <template v-slot:title>
                                    <span class="">一覧</span>
                                </template>

                                <div>
                                    <div class="form-group row">
                                        <label class="col-sm-3">キャンペーン名</label>
                                        <div class="col-sm-8">
                                            <div class="d-flex align-items-center">
                                                <div style="position: relative; width: 100%">
                                                    <div>
                                                        <multiselect
                                                            :showPointer="false"
                                                            :showNoResults="false"
                                                            selectedLabel=""
                                                            deselectLabel=""
                                                            placeholder=""
                                                            selectLabel=""
                                                            :showNoOptions="false"
                                                            track-by="id"
                                                            label="name"
                                                            v-model="campaign"
                                                            :options="listDataMasterCampaign"
                                                        ></multiselect>
                                                        <i
                                                            v-if="campaign.id"
                                                            @click="
                                                                $event.stopPropagation();
                                                                campaign = {
                                                                    id: '',
                                                                    name: ''
                                                                };
                                                            "
                                                            style="
                                                                cursor: pointer;
                                                                position: absolute;
                                                                right: 15px;
                                                                top: 11px;
                                                                background: #fff;
                                                                z-index: 9;
                                                            "
                                                            class="fa fa-window-close"
                                                        ></i>
                                                    </div>
                                                    <div v-if="checkFirst && campaign.id == ''">
                                                        <span v-if="campaign.id == ''" class="error">キャンペーン名を選択してください。</span>
                                                    </div>
                                                </div>
                                                <button
                                                    @click="goToEdit('/campaign/list')"
                                                    class="btn btn-primary ml-3"
                                                    style="margin-bottom: 1px; min-width: 80px"
                                                >
                                                    変更
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>項目</th>
                                                    <th>ファイル名</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colspan="2">条件設定なし</td>
                                                </tr>
                                                <tr v-for="(item, key) in listNotCondition" :key="`condition${key}`">
                                                    <td>{{ item.label }}</td>
                                                    <td>
                                                        <div class="d-flex align-items-center mb-1" v-for="item in item.data" :key="item.id">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="conditions[item.id]"
                                                                    :id="`checks${item.id}`"
                                                                    :value="item.source"
                                                                    class="custom-control-input"
                                                                />
                                                                <label :for="`checks${item.id}`" class="custom-control-label">{{ item.name }}</label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">条件設定あり</td>
                                                </tr>
                                                <tr v-for="(item, key) in listCondition" :key="`conditiond${key}`">
                                                    <td>{{ item.label }}</td>
                                                    <td>
                                                        <div
                                                            class="d-flex align-items-center item2"
                                                            style="border-bottom: 1px solid #efefef; padding-bottom: 5px"
                                                            v-for="(jtem, jey) in item.data"
                                                            :key="jtem.id"
                                                        >
                                                            <div style="width: 100%">
                                                                <div v-if="!item.isCheckbox">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            v-model="conditions[jtem.id]"
                                                                            :id="`checks${jtem.id}`"
                                                                            :value="jtem.source"
                                                                            class="custom-control-input"
                                                                        />
                                                                        <label :for="`checks${jtem.id}`" class="custom-control-label">{{
                                                                            jtem.name
                                                                        }}</label>
                                                                    </div>
                                                                    <span v-if="conditions[jtem.id] && jtem.client && !jtem.client.id" class="error"
                                                                        >出力条件を設定してください。</span
                                                                    >
                                                                </div>
                                                                <div v-else>
                                                                    <p class="custom-control font-weight-bold mb-0">
                                                                        {{ jtem.name }}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <button
                                                                :disabled="!campaign.id"
                                                                v-if="jtem.preview"
                                                                @click="setPreview()"
                                                                class="btn btn-sm btn-outline-primary"
                                                                style="min-width: 90px"
                                                            >
                                                                プレビュー
                                                            </button>

                                                            <template v-else>
                                                                <button
                                                                    v-if="item.label == '規約'"
                                                                    :disabled="!conditions[jtem.id] || !campaign.id"
                                                                    @click="setDataOther(jtem, key, jey)"
                                                                    class="btn btn-sm btn-outline-info"
                                                                    style="min-width: 70px"
                                                                >
                                                                    設定
                                                                </button>
                                                                <button
                                                                    v-else-if="item.label == $t('document.partnerRequestSheet')"
                                                                    :disabled="!campaign.id"
                                                                    @click="
                                                                        () => {
                                                                            $router.push({
                                                                                path: `${jtem.path}?id=${campaign.id}`
                                                                            });
                                                                        }
                                                                    "
                                                                    class="btn btn-sm btn-outline-info"
                                                                    style="min-width: 70px"
                                                                >
                                                                    {{ $t('btn.detail') }}
                                                                </button>
                                                                <button
                                                                    v-else-if="item.label != '当選配信文言'"
                                                                    :disabled="!conditions[jtem.id] || !campaign.id"
                                                                    @click="setData(item.label, jtem, key, jey)"
                                                                    class="btn btn-sm btn-outline-info"
                                                                    style="min-width: 70px"
                                                                >
                                                                    設定
                                                                </button>
                                                                <button
                                                                    v-else
                                                                    :disabled="!campaign.id"
                                                                    @click="showPreview(jtem.id)"
                                                                    class="btn btn-sm btn-outline-primary"
                                                                    style="min-width: 90px"
                                                                >
                                                                    プレビュー
                                                                </button>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="text-center mt-4 position-relative"></div>
                            </b-tab>
                            <b-tab @click="handleTabClick(1)" class="border-0">
                                <template v-slot:title>
                                    <span class="">出力結果</span>
                                </template>

                                <div>
                                    <div class="form-group row">
                                        <div class="col-sm-8 ml-0">
                                            <div class="d-flex align-items-center">
                                                <button
                                                    class="btn btn-primary"
                                                    @click="
                                                        page = 1;
                                                        getHistory();
                                                    "
                                                >
                                                    <i class="ri-refresh-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>ファイル名</th>
                                                    <th>ステータス</th>
                                                    <th>形式</th>
                                                    <th>時間</th>
                                                    <th>サイズ</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="historyListDocument.length > 0 && isLoadingTable">
                                                <tr v-for="item in historyListDocument" :key="item.id">
                                                    <td>
                                                        <a target="_blank" :href="item.url">{{ item.name }}</a>
                                                    </td>
                                                    <td>
                                                        <p
                                                            class="text-center p-2 rounded"
                                                            :style="`background-color: ${handleBgStatus(item.status)}; color:#ffff`"
                                                        >
                                                            {{ $t(item.status) }}
                                                        </p>
                                                    </td>
                                                    <td>{{ item.file_type }}</td>
                                                    <td>{{ getCurrentDateTime(item.created_at) }}</td>
                                                    <td>{{ item.size ? Number(item.size.toFixed(1)) : '0' }}mb</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else-if="!isLoadingTable">
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <div class="d-flex justify-content-center">
                                                                <LoadingIcon />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.searchEmpty') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <pagination
                                        v-if="total && historyListDocument.length > 0 && isLoadingTable"
                                        :total="total"
                                        :page.sync="page"
                                        :per-page="limit"
                                    ></pagination>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <!--            <button type="button" class="btn btn-light">-->
                        <!--              キャンセル-->
                        <!--            </button>-->
                        <button type="button" @click="register()" class="btn btn-primary ml-3">出力</button>
                    </div>
                </div>
            </Footer>

            <PopUpExportSchedule :config="configExportSchedule" :exportSchedule.sync="objExportSchedule" />

            <b-modal no-close-on-backdrop id="modal-condition" title="条件設定" title-class="font-18" scrollable hide-footer>
                <form class="needs-validation" autocomplete="off">
                    <div class="form-group row">
                        <label class="col-sm-4">エンドクライアント</label>
                        <div class="col-sm-8 pl-0">
                            <div style="position: relative">
                                <multiselect
                                    :showPointer="false"
                                    :showNoResults="false"
                                    selectedLabel=""
                                    deselectLabel=""
                                    placeholder=""
                                    selectLabel=""
                                    :showNoOptions="false"
                                    track-by="id"
                                    label="name"
                                    :maxHeight="120"
                                    v-model="clientCondition"
                                    :options="listDataMasterClients"
                                ></multiselect>
                                <i
                                    v-if="clientCondition && clientCondition.id"
                                    @click="
                                        $event.stopPropagation();
                                        clientCondition = {
                                            id: '',
                                            name: ''
                                        };
                                    "
                                    style="cursor: pointer; position: absolute; right: 14px; top: 11px; background: #fff; z-index: 9"
                                    class="fa fa-window-close"
                                ></i>
                            </div>
                            <span v-if="checkSecond && clientCondition && !clientCondition.id" class="error"
                                >エンドクライアントを選択してください。</span
                            >
                        </div>
                    </div>

                    <div v-if="agencyCondition1">
                        <div class="form-group row">
                            <label class="col-sm-4">代理店</label>
                            <div class="col-sm-8 pl-0">
                                <div style="position: relative">
                                    <multiselect
                                        :showPointer="false"
                                        :showNoResults="false"
                                        selectedLabel=""
                                        deselectLabel=""
                                        placeholder=""
                                        selectLabel=""
                                        :showNoOptions="false"
                                        track-by="id"
                                        label="name"
                                        :maxHeight="50"
                                        v-model="agencyCondition"
                                        :options="listDataMasterAgencies"
                                    ></multiselect>
                                    <i
                                        v-if="agencyCondition && agencyCondition.id"
                                        @click="
                                            $event.stopPropagation();
                                            agencyCondition = {
                                                id: '',
                                                name: ''
                                            };
                                        "
                                        style="cursor: pointer; position: absolute; right: 14px; top: 11px; background: #fff; z-index: 9"
                                        class="fa fa-window-close"
                                    ></i>
                                </div>
                                <span v-if="checkSecond && agencyCondition && !agencyCondition.id" class="error">代理店を選択してください。</span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4" for="department">外部パートナー</label>
                            <div class="col-sm-8 pl-0">
                                <MultiSelectCommon
                                    :id="`select-partner`"
                                    :value.sync="partnerCondition"
                                    :options="optionsPartner"
                                    :config.sync="configPartner"
                                    @select="configPartner.errorPartner = ''"
                                />
                                <div v-if="configPartner.errorPartner" class="invalid-feedback d-block">
                                    <span>{{ configPartner.errorPartner }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="d-flex justify-content-center">
                            <div class="text-center flex-fill">
                                <button type="button" class="btn btn-light mr-3" @click="$bvModal.hide('modal-condition')">キャンセル</button>
                                <button type="button" v-if="agencyCondition1" @click="setConditionManual()" class="btn btn-primary">保存</button>
                                <button type="button" v-else @click="setCondition()" class="btn btn-primary">保存</button>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

            <b-modal size="xl" no-close-on-backdrop id="modal-condition-other" title="条件設定" title-class="font-18" scrollable hide-footer>
                <form class="needs-validation" autocomplete="off">
                    <div style="height: 78vh; position: relative">
                        <b-tabs v-if="!isLoadingConditionOther" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                            <b-tab active class="border-0" @click="handleChangeTab()">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="">条件設定</span>
                                </template>

                                <div style="height: 600px; overflow-y: auto; overflow-x: hidden">
                                    <div class="form-group row">
                                        <label class="col-sm-3">エンドクライアント</label>
                                        <div class="col-sm-9 pl-0">
                                            <div style="position: relative">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="name"
                                                    :maxHeight="120"
                                                    v-model="clientCondition"
                                                    :options="listDataMasterClients"
                                                ></multiselect>
                                                <i
                                                    v-if="clientCondition && clientCondition.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        clientCondition = {
                                                            id: '',
                                                            name: ''
                                                        };
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 14px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                            <span v-if="checkSecond && clientCondition && !clientCondition.id" class="error"
                                                >エンドクライアントを選択してください。</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3">キャンペーン種別</label>
                                        <div class="col-sm-8">
                                            <div class="d-flex">
                                                <div
                                                    class="custom-control custom-radio mr-4"
                                                    v-for="item in listDataMasterCampaignType"
                                                    :key="item.id + 's'"
                                                >
                                                    <input
                                                        type="radio"
                                                        @change="changeTab()"
                                                        v-model="campaignType"
                                                        :id="`check${item.id}`"
                                                        :value="item.value"
                                                        class="custom-control-input"
                                                    />
                                                    <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr v-if="listDataMasterRuleGeneral && listDataMasterRuleGeneral.length > 0">
                                                        <td :rowspan="listDataMasterRuleGeneral && listDataMasterRuleGeneral.length">共通</td>
                                                        <td @mouseup="handleSelectTextTab1(listDataMasterRuleGeneral[0].id)">
                                                            {{ listDataMasterRuleGeneral[0].rule }}
                                                        </td>
                                                        <td>
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="listDataMasterRuleGeneralCheck.rules"
                                                                    :id="`checkard${listDataMasterRuleGeneral[0].id}`"
                                                                    :value="listDataMasterRuleGeneral[0].id"
                                                                    class="custom-control-input"
                                                                    @change="handleGetDetailConditionsTab1(listDataMasterRuleGeneralCheck.rules)"
                                                                />
                                                                <label
                                                                    :for="`checkard${listDataMasterRuleGeneral[0].id}`"
                                                                    class="custom-control-label"
                                                                ></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <template v-for="(item, k) in listDataMasterRuleGeneral">
                                                        <tr v-if="k > 0" :key="item.id">
                                                            <td>
                                                                <div @mouseup="handleSelectTextTab1(item.id)">
                                                                    {{ item.rule }}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        v-model="listDataMasterRuleGeneralCheck.rules"
                                                                        :id="`checkard${item.id}`"
                                                                        :value="item.id"
                                                                        class="custom-control-input"
                                                                        @change="handleGetDetailConditionsTab1(listDataMasterRuleGeneralCheck.rules)"
                                                                    />
                                                                    <label :for="`checkard${item.id}`" class="custom-control-label"></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-if="campaignType == 'クローズ'">
                                                        <tr v-if="listDataMasterRuleClose && listDataMasterRuleClose.length > 0">
                                                            <td :rowspan="listDataMasterRuleClose.length">クローズ</td>
                                                            <td>{{ listDataMasterRuleClose[0].rule }}</td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        v-model="listDataMasterRuleCloseCheck.rules"
                                                                        :id="`checkar${listDataMasterRuleClose[0].id}`"
                                                                        :value="listDataMasterRuleClose[0].id"
                                                                        class="custom-control-input"
                                                                    />
                                                                    <label
                                                                        :for="`checkar${listDataMasterRuleClose[0].id}`"
                                                                        class="custom-control-label"
                                                                    ></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <template v-for="(item, k) in listDataMasterRuleClose">
                                                            <tr v-if="k > 0" :key="item.id">
                                                                <td>
                                                                    <div v-html="item.rule"></div>
                                                                </td>
                                                                <td>
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            v-model="listDataMasterRuleCloseCheck.rules"
                                                                            :id="`checkar${item.id}`"
                                                                            :value="item.id"
                                                                            class="custom-control-input"
                                                                        />
                                                                        <label :for="`checkar${item.id}`" class="custom-control-label"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            class="col-sm-5 ml-3 mr-2 py-2"
                                            style="word-wrap: break-word; height: 500px; overflow-y: auto; border: #efefef 1px solid"
                                        >
                                            <div
                                                v-for="(conditionCheckedTab1, idx) in listChildConditionAfterCheckedTab1"
                                                :key="idx"
                                                class="mb-3"
                                                :style="`background-color:${
                                                    conditionCheckedTab1.backgroundColor ? conditionCheckedTab1.backgroundColor : ''
                                                };color:${
                                                    conditionCheckedTab1.color ? conditionCheckedTab1.color : ''
                                                };border-bottom: 1px solid #b7b7b74d;`"
                                            >
                                                <div class="text-center">
                                                    <p class="font-weight-bold pt-1" style="font-size: 15px">
                                                        {{ conditionCheckedTab1.textParent }}
                                                    </p>
                                                </div>
                                                <div v-for="(item, k) in conditionCheckedTab1.details" :key="k">
                                                    <p class="p-2">{{ item.detail }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab class="border-0" @click="handleChangeTab()">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="">応募種別関連設定</span>
                                </template>

                                <div style="height: 600px; overflow-y: auto; overflow-x: hidden">
                                    <div class="row form-group">
                                        <label class="col-sm-3">応募種別 </label>
                                        <div class="col-sm-9">
                                            <div class="d-flex flex-wrap">
                                                <template v-for="item in listTab2">
                                                    <div class="custom-control custom-checkbox mr-4" :key="item.id + 'sss'">
                                                        <input
                                                            type="checkbox"
                                                            v-model="dataRuleTab"
                                                            :id="`checka${item.id}`"
                                                            :value="item.id"
                                                            class="custom-control-input"
                                                            @change="handleChangeCategoryTab2(dataRuleTab)"
                                                        />
                                                        <label :for="`checka${item.id}`" class="custom-control-label">{{ item.value }}</label>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <table class="table table-bordered">
                                            <template v-for="jtem in listTab2">
                                                <tbody :key="jtem.id">
                                                    <template v-if="dataRuleTab.includes(jtem.id)">
                                                        <tr v-if="dataRuleTab2[jtem.id] && dataRuleTab2[jtem.id][0]">
                                                            <td :rowspan="dataRuleTab2[jtem.id].length">
                                                                {{ jtem.value }}
                                                            </td>
                                                            <td @mouseup="handleSelectTextTab2(dataRuleTab2[jtem.id][0].id)">
                                                                {{ dataRuleTab2[jtem.id][0].rule }}
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        v-model="dataRuleTab2Check[jtem.id][0].rules"
                                                                        :id="`checka${dataRuleTab2[jtem.id][0].id}`"
                                                                        :value="dataRuleTab2[jtem.id][0].id"
                                                                        class="custom-control-input"
                                                                        @change="
                                                                            handleGetDetailConditionsTab2(
                                                                                dataRuleTab2Check[jtem.id][0],
                                                                                'model_checked'
                                                                            )
                                                                        "
                                                                    />
                                                                    <label
                                                                        :for="`checka${dataRuleTab2[jtem.id][0].id}`"
                                                                        class="custom-control-label"
                                                                    ></label>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <template v-for="(item, k) in dataRuleTab2[jtem.id]">
                                                            <tr v-if="k > 0" :key="item.id">
                                                                <td>
                                                                    <div @mouseup="handleSelectTextTab2(item.id)">
                                                                        {{ item.rule }}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            v-model="dataRuleTab2Check[jtem.id][k].rules"
                                                                            :id="`checka${item.id}`"
                                                                            :value="item.id"
                                                                            class="custom-control-input"
                                                                            @change="
                                                                                handleGetDetailConditionsTab2(
                                                                                    dataRuleTab2Check[jtem.id][k],
                                                                                    'model_checked'
                                                                                )
                                                                            "
                                                                        />
                                                                        <label :for="`checka${item.id}`" class="custom-control-label"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div
                                            class="col-sm-5 ml-3 mr-2 py-2"
                                            style="word-wrap: break-word; height: 500px; overflow-y: auto; border: #efefef 1px solid"
                                        >
                                            <div
                                                v-for="(conditionCheckedTab2, idx) in listChildConditionAfterCheckedTab2"
                                                :key="idx"
                                                class="mb-3"
                                                :style="`background-color:${
                                                    conditionCheckedTab2.backgroundColor ? conditionCheckedTab2.backgroundColor : ''
                                                };color:${
                                                    conditionCheckedTab2.color ? conditionCheckedTab2.color : ''
                                                };border-bottom: 1px solid #b7b7b74d;`"
                                            >
                                                <div class="text-center">
                                                    <p class="font-weight-bold pt-1" style="font-size: 15px">
                                                        {{ conditionCheckedTab2.textParent }}
                                                    </p>
                                                </div>
                                                <div v-for="(item, k) in conditionCheckedTab2.details" :key="k">
                                                    <p class="p-2">
                                                        {{ item.detail }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                        <div v-else style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
                            <LoadingIcon />
                        </div>
                    </div>
                    <div>
                        <div class="d-flex justify-content-center pt-3">
                            <div class="text-center flex-fill">
                                <button type="button" class="btn btn-light mr-3" @click="$bvModal.hide('modal-condition-other')">キャンセル</button>
                                <button type="button" @click="setConditionRule()" class="btn btn-primary">保存</button>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

            <b-modal size="lg" no-close-on-backdrop id="modal-preview" title="内容" title-class="font-18" scrollable hide-footer>
                <form class="needs-validation" autocomplete="off">
                    <div style="height: 79vh; overflow-y: auto; position: relative">
                        <div class="form-group" v-if="!isLoadingTemplate">
                            <div v-html="templateDetail.content"></div>
                        </div>
                        <div v-else style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
                            <LoadingIcon />
                        </div>
                    </div>
                </form>
                <div>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="button" class="btn btn-light mr-3" @click="$bvModal.hide('modal-preview')">閉じる</button>
                        </div>
                    </div>
                </div>
            </b-modal>

            <div class="box3">
                <PopUpFAQ :config.sync="configFaq" />
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
.item2 {
    padding-top: 5px;
}
.item2:last-child {
    border: 0 !important;
}
.item2:hover {
    background: rgba(239, 239, 239, 0.28);
}
</style>
