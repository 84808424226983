<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { keyMaster, masterMethods, campaignMethods, agencyMethods, clientMethods, checkPermission } from '@/state/helpers';
import Pagination from '@/components/Pagination/main.vue';
import Swal from 'sweetalert2';
import DatePicker from 'vue2-datepicker';
import { campaignStatus, listContactInformations } from '@/config/var-common';
import Multiselect from 'vue-multiselect';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Multiselect,
        Pagination,
        DatePicker,
        Layout,
        PageHeader,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            title: 'キャンペーン一覧',
            bookmarkName: '',
            campaignObject: {},
            listDataMasterCampaign: [],
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    active: true
                }
            ],
            campaignStatus: campaignStatus,
            listData: [],
            listDataMasterFaq: [],
            listDataMasterClients: [],
            listClientsSupport: [],
            listDataMasterAgencies: [],
            listAgenciesSupport: [],
            listDataMasterSaleStaf: [],
            listDataMasterInstructorStaf: [],
            listDataAnswer: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                name: ''
            },
            date_from: {
                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            },
            date_to: {
                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            },
            dataAnswerObj: {},
            perPageList: [10, 15, 20, 25],
            listDataBookmark: [],
            listContactInformations: listContactInformations,
            filter: {
                campaign_type: '',
                sale_staff: '',
                keywords: '',
                progress_status: [],
                person_in_charge: ''
            },
            has: {
                client: '',
                client_pic: '',
                agency: '',
                agency_pic: '',
                contact_information: []
            },
            dataAnswer: {
                answer: '',
                faq_id: 3
            },
            paramsQuery: {
                is_approved: '',
                team: '',
                sale_staff_team: '',
                progress_status: '',
                partner_pic: ''
            },
            numberQuery: {
                client: '',
                agency: '',
                partner: ''
            },
            customFormatDate: 'YYYY-MM-DD',
            customFormatMonth: 'YYYY-MM',
            listDataMasterCampaignType: [],
            clientObject: {},
            person_in_charge: {},
            sale_staff: {},
            clientPicObject: {},
            agenciesObject: {},
            agenciesPicObject: {},

            checkShowFilter: false,
            listTagFreeWord: [],
            listTagKeyWord: [],
            configKeyword: {
                arrayTag: [],
                placeholder: 'キャンペーン名　担当者 ...',
                isShowLable: true
            },
            configKeyword1: {
                arrayTag: [],
                placeholder: ''
            },
            isLoading: false
        };
    },
    watch: {
        campaignObject: function (newName) {
            if (newName) {
                this.form.name = newName.name;
            }
        },
        clientObject: function (newName) {
            if (newName) {
                this.has.client = newName.id;
            }
        },
        sale_staff: function (newName) {
            if (newName) {
                this.filter.sale_staff = newName.id;
            }
        },
        person_in_charge: function (newName) {
            if (newName) {
                this.filter.person_in_charge = newName.id;
            }
        },
        clientPicObject: function (newName) {
            if (newName) {
                this.has.client_pic = newName.id;
            }
        },
        agenciesObject: function (newName) {
            if (newName) {
                this.has.agency = newName.id;
            }
        },
        agenciesPicObject: function (newName) {
            if (newName) {
                this.has.agency_pic = newName.id;
            }
        },
        'date_from.secretariat_end_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_from.secretariat_end_date_y = date[0];
                this.date_from.secretariat_end_date_m = date[1];
                this.date_from.secretariat_end_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },

        'date_to.secretariat_end_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_to.secretariat_end_date_y = date[0];
                this.date_to.secretariat_end_date_m = date[1];
                this.date_to.secretariat_end_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },

        'date_from.campaign_start_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_from.campaign_start_date_y = date[0];
                this.date_from.campaign_start_date_m = date[1];
                this.date_from.campaign_start_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'date_to.campaign_start_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_to.campaign_start_date_y = date[0];
                this.date_to.campaign_start_date_m = date[1];
                this.date_to.campaign_start_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'date_from.campaign_end_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_from.campaign_end_date_y = date[0];
                this.date_from.campaign_end_date_m = date[1];
                this.date_from.campaign_end_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'date_to.campaign_end_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_to.campaign_end_date_y = date[0];
                this.date_to.campaign_end_date_m = date[1];
                this.date_to.campaign_end_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        },
        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        },
        'configKeyword1.arrayTag': function (newOld) {
            this.filter.keywords = newOld;
        }
    },
    mounted() {
        if (this.$route.query) {
            this.getList();
        } else {
            this.getList();
        }
        this.getListBookmark();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...campaignMethods,
        ...clientMethods,
        ...agencyMethods,
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = this.listTagKeyWord;
                this.getListMasterCampaignType();
                this.getListMasterClients();
                this.getListMasterAgencies();
                this.getListMasterSaleStaf();
                this.getListMasterInstructorStaf();
            } else {
                this.configKeyword.placeholder = 'キャンペーン名　担当者 ...';
                this.configKeyword.arrayTag = this.listTagFreeWord;
            }
        },
        getListMasterCampaignType() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataMasterCampaignType = data;
            });
        },
        getListBookmark() {
            this.getBookmark('filter[page]=campaign').then((data) => {
                if (data.code == 200) {
                    this.listDataBookmark = data.data;
                }
            });
        },
        getListMasterClients() {
            this.listMaster('all/clients').then((data) => {
                this.listDataMasterClients = data;
            });
        },
        getListMasterClientsSupport() {
            this.listClientsSupport = [];
            this.clientPicObject = {};
            if (this.clientObject.id) {
                this.listClientSupport(this.clientObject.id).then((data) => {
                    this.listClientsSupport = data.data;
                    this.loading = false;
                });
            }
        },
        getListMasterAgencies() {
            this.listMaster('all/agencies').then((data) => {
                this.listDataMasterAgencies = data;
            });
        },
        getListMasterAgenciesSupport() {
            this.listAgenciesSupport = [];
            this.agenciesPicObject = {};
            if (this.agenciesObject.id) {
                this.listAgencySupport(this.agenciesObject.id).then((data) => {
                    this.listAgenciesSupport = data.data;
                    this.loading = false;
                });
            }
        },
        getListMasterSaleStaf() {
            this.listMaster('users?filters[role]=2' + '&limit=999').then((data) => {
                this.listDataMasterSaleStaf = data;
            });
        },
        getListMasterInstructorStaf() {
            this.listMaster('users?filters[role]=3' + '&limit=999').then((data) => {
                this.listDataMasterInstructorStaf = data;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];
            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${decodeURIComponent(value)}`);
                    }
                }

                if (this.filter) {
                    for (const [key, value] of Object.entries(this.filter)) {
                        if (key && value) query.push(`filters[${key}]=${value}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${value}`);
                    }
                }
                if (this.date_from) {
                    let date_from = Object.assign({}, this.date_from);
                    if (date_from.campaign_end_date_y || date_from.campaign_end_date_m || date_from.campaign_end_date_d)
                        date_from.campaign_end_date =
                            date_from.campaign_end_date_y + '-' + date_from.campaign_end_date_m + '-' + date_from.campaign_end_date_d;
                    if (date_from.campaign_start_date_y || date_from.campaign_start_date_m || date_from.campaign_start_date_d)
                        date_from.campaign_start_date =
                            date_from.campaign_start_date_y + '-' + date_from.campaign_start_date_m + '-' + date_from.campaign_start_date_d;
                    if (date_from.secretariat_end_date_y || date_from.secretariat_end_date_m || date_from.secretariat_end_date_d)
                        date_from.secretariat_end_date =
                            date_from.secretariat_end_date_y + '-' + date_from.secretariat_end_date_m + '-' + date_from.secretariat_end_date_d;
                    delete date_from.campaign_end_date_m;
                    delete date_from.campaign_end_date_d;
                    delete date_from.campaign_end_date_y;
                    delete date_from.campaign_start_date_y;
                    delete date_from.campaign_start_date_m;
                    delete date_from.campaign_start_date_d;
                    delete date_from.secretariat_end_date_y;
                    delete date_from.secretariat_end_date_m;
                    delete date_from.secretariat_end_date_d;
                    for (const [key, value] of Object.entries(date_from)) {
                        if (key && value) query.push(`from[${key}]=${value}`);
                    }
                }
                if (this.date_to) {
                    let date_to = Object.assign({}, this.date_to);
                    if (date_to.campaign_end_date_y || date_to.campaign_end_date_m || date_to.campaign_end_date_d)
                        date_to.campaign_end_date =
                            date_to.campaign_end_date_y + '-' + date_to.campaign_end_date_m + '-' + date_to.campaign_end_date_d;
                    if (date_to.campaign_start_date_y || date_to.campaign_start_date_m || date_to.campaign_start_date_d)
                        date_to.campaign_start_date =
                            date_to.campaign_start_date_y + '-' + date_to.campaign_start_date_m + '-' + date_to.campaign_start_date_d;
                    if (date_to.secretariat_end_date_y || date_to.secretariat_end_date_m || date_to.secretariat_end_date_d)
                        date_to.secretariat_end_date =
                            date_to.secretariat_end_date_y + '-' + date_to.secretariat_end_date_m + '-' + date_to.secretariat_end_date_d;
                    delete date_to.campaign_end_date_m;
                    delete date_to.campaign_end_date_d;
                    delete date_to.campaign_end_date_y;
                    delete date_to.campaign_start_date_y;
                    delete date_to.campaign_start_date_m;
                    delete date_to.campaign_start_date_d;
                    delete date_to.secretariat_end_date_y;
                    delete date_to.secretariat_end_date_m;
                    delete date_to.secretariat_end_date_d;
                    for (const [key, value] of Object.entries(date_to)) {
                        if (key && value) query.push(`to[${key}]=${value}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            if (this.$route.query) {
                if (this.$route.query.is_approved) {
                    this.paramsQuery.is_approved = this.$route.query.is_approved;
                }
                if (this.$route.query.team_id) {
                    this.paramsQuery.team = this.$route.query.team_id;
                }
                if (this.$route.query.sale_staff_team) {
                    this.paramsQuery.sale_staff_team = this.$route.query.sale_staff_team;
                }
                if (this.$route.query.progress_status) {
                    this.paramsQuery.progress_status = this.$route.query.progress_status;
                }
                if (this.$route.query.partner_pic) {
                    this.paramsQuery.partner_pic = this.$route.query.partner_pic;
                }

                if (this.$route.query.agency_id) {
                    this.numberQuery.agency = this.$route.query.agency_id;
                }
                if (this.$route.query.client_id) {
                    this.numberQuery.client = this.$route.query.client_id;
                }
                if (this.$route.query.partner_id) {
                    this.numberQuery.partner = this.$route.query.partner_id;
                }

                if (this.numberQuery.agency || this.numberQuery.client || this.numberQuery.partner) {
                    for (const [key, value] of Object.entries(this.numberQuery)) {
                        if (key && value) {
                            query.push(`has[${key}]=${value}`);
                        }
                    }
                } else {
                    for (const [key, value] of Object.entries(this.paramsQuery)) {
                        if (key && value) {
                            if (key === 'partner_pic' || key === 'sale_staff_team') {
                                query.push(`has[${key}]=${value}`);
                            } else {
                                query.push(`filters[${key}]=${value}`);
                            }
                        }
                    }
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listCampaign(query).then((data) => {
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        search() {
            this.getList();
        },
        setBookmark(item) {
            let query = JSON.parse(item.query.data);
            this.checkShowFilter = query.checkShowFilter;
            this.configKeyword = query.configKeyword;
            this.date_from = query.date_from;
            this.date_to = query.date_to;
            this.filter = query.filter;
            this.form = query.form;
            this.getList();
        },
        deleteBookmarks(event, item) {
            event.stopPropagation();
            this.deleteBookmark(item.id).then((data) => {
                if (data.code == 200) {
                    this.getListBookmark();
                }
            });
        },
        clear() {
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名　担当者 ...'
            };
            this.configKeyword1 = {
                arrayTag: [],
                placeholder: ''
            };
            this.form = {
                name: ''
            };
            this.filter = {
                campaign_type: '',
                sale_staff: '',
                keywords: '',
                progress_status: [],
                person_in_charge: ''
            };
            this.clientObject = {};
            this.clientPicObject = {};
            this.agenciesObject = {};
            this.agenciesPicObject = {};
            this.has = {
                client: '',
                client_pic: '',
                agency: '',
                agency_pic: '',
                contact_information: []
            };
            this.date_from = {
                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            };
            (this.date_to = {
                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            }),
                (this.sale_staff = this.person_in_charge = {}),
                this.getList();
        },
        deleteObject(data) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.deleteAnswer(data).then((data) => {
                        if (data.code == 200) {
                            this.$bvToast.toast(`Your record has been deleted.`, {
                                title: `Deleted!`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                            this.getDetail(this.dataAnswer.faq_id);
                        }
                    });
                }
            });
        },
        register() {
            if (this.dataAnswer.answer && this.dataAnswer.faq_id) {
                this.registerAnswer(this.dataAnswer).then((data) => {
                    if (data.code == 200) {
                        this.dataAnswer.answer = '';
                        this.getDetail(this.dataAnswer.faq_id);
                        this.$bvToast.toast(`Create Answer Success`, {
                            title: `Success!`,
                            variant: 'success',
                            solid: true
                        });
                    }
                });
            }
        },
        edit(item) {
            if (item.answer) {
                let data = Object.assign(item);
                this.updateAnswer(data).then(() => {
                    this.$bvToast.toast(`Update Answer Success`, {
                        title: `Success!`,
                        variant: 'success',
                        solid: true
                    });
                });
            }
        },
        setDefault(item) {
            if (item.answer) {
                let data = {
                    answer_default: item.id,
                    id: this.dataAnswer.faq_id
                };
                this.updateFaqsAnswerDefault(data).then(() => {
                    this.getDetail(this.dataAnswer.faq_id);
                    this.$bvToast.toast(`Update Answer Success`, {
                        title: `Success!`,
                        variant: 'success',
                        solid: true
                    });
                });
            }
        },
        convertDate(date) {
            if (!date) return '';
            return date.replaceAll('-', '/');
        },
        renderStatus(status) {
            let index = campaignStatus.findIndex((x) => {
                return x.id == status;
            });
            if (index >= 0) {
                return campaignStatus[index].text;
            }
        },
        removeDuplicates(array, key) {
            return array.filter((item, index, self) => index === self.findIndex((t) => t[key] === item[key]));
        },
        addBookMark() {
            let query = {
                checkShowFilter: this.checkShowFilter,
                configKeyword: this.configKeyword,
                form: this.form,
                filter: this.filter,
                date_to: this.date_to,
                date_from: this.date_from
            };
            let objectData = {
                name: this.bookmarkName,
                page: 'campaign',
                query: {
                    data: JSON.stringify(query)
                }
            };
            this.addBookmark(objectData).then((data) => {
                if (data.code == 200) this.getListBookmark();
            });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">キャンペーン検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-8">
                                <form class="needs-validation" autocomplete="off">
                                    <!--                  <div class="text-right mb-3">-->
                                    <!--                    <button-->
                                    <!--                      type="button"-->
                                    <!--                      class="btn btn-success"-->
                                    <!--                      @click="handleShowFilter"-->
                                    <!--                    >-->
                                    <!--                      {{ !checkShowFilter ? "フィルター" : "閉じる" }}-->
                                    <!--                    </button>-->
                                    <!--                  </div>-->
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名</label>
                                            <div class="col-sm-9">
                                                <!--                        <multiselect placeholder="" selectLabel="" :showNoOptions="false" track-by="id"   label="name"  v-model="campaignObject" :options="listDataMasterCampaign"></multiselect>-->
                                                <input type="text" class="form-control" v-model="form.name" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン開始年月日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.campaign_start_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="date_from.campaign_start_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="date_from.campaign_start_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="date_from.campaign_start_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.campaign_start_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="date_to.campaign_start_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="date_to.campaign_start_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="date_to.campaign_start_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン終了年月日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.campaign_end_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="date_from.campaign_end_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="date_from.campaign_end_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="date_from.campaign_end_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.campaign_end_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="date_to.campaign_end_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="date_to.campaign_end_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="date_to.campaign_end_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">事務局終了年月日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.secretariat_end_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="date_from.secretariat_end_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="date_from.secretariat_end_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="date_from.secretariat_end_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.secretariat_end_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="date_to.secretariat_end_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="date_to.secretariat_end_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="date_to.secretariat_end_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン種別</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex">
                                                    <div
                                                        class="custom-control custom-radio mr-4"
                                                        v-for="item in listDataMasterCampaignType"
                                                        :key="item.id + 's'"
                                                    >
                                                        <input
                                                            type="radio"
                                                            v-model="filter.campaign_type"
                                                            :id="`radio${item.id}`"
                                                            :value="item.id"
                                                            class="custom-control-input"
                                                        />
                                                        <label :for="`radio${item.id}`" class="custom-control-label">{{ item.value }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">エンドクライアント</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    @select="getListMasterClientsSupport"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="clientObject"
                                                    :options="listDataMasterClients"
                                                ></multiselect>
                                                <i
                                                    v-if="clientObject.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        clientObject = {};
                                                        clientPicObject = {};
                                                        listClientsSupport = [];
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 25px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 pr-0" for="validationCustom05">エンドクライアント担当者</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="clientPicObject"
                                                    :options="listClientsSupport"
                                                ></multiselect>
                                                <i
                                                    v-if="clientPicObject.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        clientPicObject = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 25px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">代理店</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    @select="getListMasterAgenciesSupport"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="agenciesObject"
                                                    :options="listDataMasterAgencies"
                                                ></multiselect>
                                                <i
                                                    v-if="agenciesObject.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        agenciesObject = {};
                                                        agenciesPicObject = {};
                                                        listAgenciesSupport = [];
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 25px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">代理店担当者</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="agenciesPicObject"
                                                    :options="listAgenciesSupport"
                                                ></multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">PL営業担当者</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="sale_staff"
                                                    :options="listDataMasterSaleStaf"
                                                ></multiselect>
                                                <i
                                                    v-if="sale_staff.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        sale_staff = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 25px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">PLディレクション担当</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="person_in_charge"
                                                    :options="listDataMasterInstructorStaf"
                                                ></multiselect>

                                                <i
                                                    v-if="person_in_charge.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        person_in_charge = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 25px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">進捗ステータス</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex flex-wrap">
                                                    <div
                                                        class="custom-control custom-checkbox mr-4"
                                                        v-for="item in campaignStatus"
                                                        :key="item.id + 's'"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            v-model="filter.progress_status"
                                                            :id="`checkd${item.id}`"
                                                            :value="item.id"
                                                            class="custom-control-input"
                                                        />
                                                        <label :for="`checkd${item.id}`" class="custom-control-label">{{ item.text }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">検索キーワード</label>
                                            <div class="col-sm-9">
                                                <KeyWordTag :config="configKeyword1" class="" />
                                                <!--                        <input type="text" v-model="filter.keywords" class="form-control" />-->
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">お問い合わせ窓口</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex">
                                                    <div
                                                        class="custom-control custom-checkbox mr-4"
                                                        v-for="item in listContactInformations"
                                                        :key="item.id + 'ss'"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            v-model="has.contact_information"
                                                            :id="`checka${item.id}`"
                                                            :value="item.id"
                                                            class="custom-control-input"
                                                        />
                                                        <label :for="`checka${item.id}`" class="custom-control-label">{{ item.text }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                                <div class="form-group row mt-4">
                                    <label class="col-sm-3" for="validationCustom05">Bookmark</label>
                                    <div class="col-sm-9">
                                        <button
                                            type="button"
                                            v-for="(item, k) in listDataBookmark"
                                            @click="setBookmark(item)"
                                            :key="k"
                                            class="btn btn-outline-info mr-2"
                                            style="position: relative"
                                        >
                                            {{ item.name }}
                                            <i
                                                @click="deleteBookmarks($event, item)"
                                                style="position: absolute; top: -2px; right: -2px"
                                                class="fa fa-window-close"
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            キャンペーン検索結果 <span>件数（{{ total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="d-flex align-items-center input-group-sm" style="white-space: nowrap">
                                    検索条件：
                                    <input type="text" class="form-control ml-3" v-model="bookmarkName" style="width: 200px" />
                                    <button class="btn btn-sm btn-primary ml-2" @click="addBookMark()">保存</button>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="pagination__items clearfix pb-3 text-right">
                                    <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                                    <span>表示件数</span>
                                    <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                        <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>CP 番号</th>
                                        <th>営業担当</th>
                                        <th>メイン担当</th>
                                        <th>エンドクライアント</th>
                                        <th>代理店</th>
                                        <th>種別</th>
                                        <th>キャンペーン名</th>
                                        <th>ステータス</th>
                                        <th>事務局開始日</th>
                                        <th>事務局終了日</th>
                                        <th>最終更新者</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template v-for="(item, k) in listData">
                                        <tr :key="item.id">
                                            <td>
                                                {{ parseInt((page - 1) * limit) + parseInt(k + 1) }}
                                            </td>
                                            <td>{{ item.sale_staff ? item.sale_staff.name : '' }}</td>
                                            <td>
                                                {{ item.person_in_charge ? item.person_in_charge.name : '' }}
                                            </td>
                                            <td>
                                                <span v-for="(i, j) in removeDuplicates(item.clients, 'name')" :key="'c' + j"
                                                    >{{ j > 0 ? ', ' : '' }}{{ i.name }}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-for="(i, j) in removeDuplicates(item.agencies, 'name')" :key="'cad' + j"
                                                    >{{ j > 0 ? ', ' : '' }}{{ i.name }}</span
                                                >
                                            </td>
                                            <td>
                                                {{ item.campaign_type ? item.campaign_type.value : '' }}
                                            </td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ renderStatus(item.progress_status) }}</td>
                                            <td>{{ convertDate(item.secretariat_start_date) }}</td>
                                            <td>{{ convertDate(item.secretariat_end_date) }}</td>
                                            <td>{{ item.updated_by ? item.updated_by.name : '' }}</td>

                                            <td :align="checkPermission('campaign.edit') ? 'left' : 'center'">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-info"
                                                    :class="
                                                        item.is_approved != 0 && item.is_locked != 1 && checkPermission('campaign.edit')
                                                            ? 'border-right-1'
                                                            : ''
                                                    "
                                                    @click="goToEdit('/campaign/form/view', item)"
                                                    >詳細</a
                                                >
                                                <a
                                                    v-if="item.is_approved != 0 && item.is_locked != 1 && checkPermission('campaign.edit')"
                                                    :class="
                                                        item.is_approved != 0 && item.is_locked != 1 && checkPermission('campaign.edit')
                                                            ? 'border-right-1'
                                                            : ''
                                                    "
                                                    href="javascript:void(0)"
                                                    class="text-success"
                                                    @click="goToEdit('/campaign/edit', item)"
                                                    >編集</a
                                                >
                                                <a
                                                    v-if="
                                                        item.is_approved != 0 &&
                                                        item.is_locked != 1 &&
                                                        checkPermission('campaign.edit') &&
                                                        checkPermission('campaign.register')
                                                    "
                                                    href="javascript:void(0)"
                                                    @click="goToEdit('/documents', item)"
                                                    class="text-primary"
                                                    >資料生成</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
    white-space: nowrap;
}
th,
td {
    white-space: nowrap;
}
th:last-child,
td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
}
</style>
